@font-face {
  font-family: euclidCircularB;
  src: url('./fonts/EuclidCircularB-Regular-WebM.woff2') format('woff2'),
    url('./fonts/EuclidCircularB-Regular-WebM.ttf') format('truetype'),
    url('./fonts/EuclidCircularB-Regular-WebM.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: euclidCircularB;
  src: url('./fonts/EuclidCircularB-Semibold-WebM.woff2') format('woff2'),
    url('./fonts/EuclidCircularB-Semibold-WebM.ttf') format('truetype'),
    url('./fonts/EuclidCircularB-Semibold-WebM.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
